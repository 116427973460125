import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CiWallet } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeUser } from "../../api/user";
import Loader from "../../component/Loader";
import Paginations from "../../component/Pagination";
import { variants } from "../../constant/Variants";
import DeleteModal from "./../../component/Modal/Delete";
import {
  FetchUserList,
  lists,
  totalUserCountSelector,
  userListSelector,
} from "./UserSlice";

function User() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const list = useSelector(userListSelector);
  const total = useSelector(totalUserCountSelector);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const handlePage = (val) => {
    setPage(val);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(FetchUserList({ page: page, limit: 10, search: search })).then(
      () => {
        setLoading(false);
      }
    );
  }, [dispatch, page, search]);

  useEffect(() => {
    getList();
  }, [getList]);

  const confirmDelete = () => {
    removeUser({ userId: delId }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getList();
      } else {
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    return () => {
      dispatch(lists(null));
    };
  }, []);

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img src="assets/images/users-selected.png" alt="" /> Total
                users {total}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search user by name"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
              <Link to="/add-user" className="">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-sm-9 col-md-9 col-lg-10">
            <ul
              className="nav nav-pills group-nav mb-3"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-new-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-new"
                  type="button"
                  role="tab"
                  aria-controls="pills-new"
                  aria-selected="true"
                >
                  Users
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-completed-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-completed"
                  type="button"
                  role="tab"
                  aria-controls="pills-completed"
                  aria-selected="false"
                  onClick={() => {
                    navigate("/student");
                  }}
                >
                  Students
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Email ID</th>
                    <th scope="col">Date of Birth</th>
                    <th scope="col">Contact Number</th>
                    <th scope="col">Full Address</th>

                    <th scope="col">action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={9}>
                      <Loader />
                    </td>
                  ) : (
                    <>
                      {list?.length > 0 ? (
                        list?.map((item, i) => {
                          return (
                            <tr variants={variants.card} key={i}>
                              <td>{item?.id || "NA"}</td>
                              <td>
                                {item?.first_name ? item?.first_name : "NA"}
                              </td>
                              <td>
                                {item?.last_name ? item?.last_name : "NA"}
                              </td>
                              <td>{item?.username ? item?.username : "NA"}</td>
                              <td>{item?.email ? item?.email : "NA"}</td>
                              <td>
                                {item?.address_details?.dob &&
                                item?.address_details?.dob !== "0000-00-00"
                                  ? moment(item?.address_details?.dob).format(
                                      "DD MMM YYYY"
                                    )
                                  : item?.address_details?.dob_year
                                  ? `${moment(
                                      `${item?.address_details?.dob_year}-${item?.address_details?.dob_month}-${item?.address_details?.dob_day}`
                                    ).format("DD MMM YYYY")}`
                                  : "NA"}
                              </td>
                              <td>
                                {item?.address_details?.mobile
                                  ? `${
                                      item?.address_details?.countryCode
                                        ? item?.address_details?.countryCode
                                        : ""
                                    }${item?.address_details?.mobile}`
                                  : "NA"}
                              </td>
                              <td>
                                {item?.address_details
                                  ? `${
                                      item?.address_details?.property_number
                                        ? `${item?.address_details?.property_number}, `
                                        : ""
                                    }${
                                      item?.address_details?.property_name
                                        ? `${item?.address_details?.property_name}, `
                                        : ""
                                    }${
                                      item?.address_details?.street_number
                                        ? `${item?.address_details?.street_number}, `
                                        : ""
                                    }${
                                      item?.address_details?.town
                                        ? `${item?.address_details?.town}, `
                                        : ""
                                    }${
                                      item?.address_details?.country
                                        ? `${item?.address_details?.country}`
                                        : ""
                                    }${
                                      item?.address_details?.postal_code
                                        ? `-${item?.address_details?.postal_code}`
                                        : ""
                                    }`
                                  : "NA"}
                              </td>

                              <td>
                                <div className="d-flex justify-content-center action">
                                  <button className="btn" title="View User">
                                    <Link to={`/user/${item?.id}`}>
                                      <img
                                        src="assets/images/view.png"
                                        alt=""
                                      />
                                    </Link>
                                  </button>
                                  <button className="btn" title="Edit User">
                                    <Link to={`/edit-user/${item?.id}`}>
                                      <img
                                        src="assets/images/edit.png"
                                        alt=""
                                      />
                                    </Link>
                                  </button>
                                  <button className="btn" title="Wallet History">
                                    <Link to={`/wallet-history/${item?.id}`}>
                                      {/* <img
                                        src="assets/images/payment-refund-selected.png"
                                        alt=""
                                      /> */}
                                      <CiWallet size={25} color="blue"/>
                                    </Link>
                                  </button>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      setDelId(item.id);
                                      handleShow();
                                    }}
                                    title="Delete User"
                                  >
                                    <img
                                      src="assets/images/delete.png"
                                      alt=""
                                    />
                                  </button>
                                  {/* <button className="btn">
                                <input
                                  type="checkbox"
                                  className="toggle"
                                  checked=""
                                />
                              </button> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10}>No data found.</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {list?.length > 0 && (
            <Paginations
              handlePage={handlePage}
              page={page}
              total={total && total}
            />
          )}
        </div>
      </div>
      <DeleteModal
        page="User"
        show={show}
        handleShow={handleShow}
        cancel={() => {
          setShow(false);
        }}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default User;
