import moment from "moment/moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { acceptRejectRequest } from "../../api/accountDeletion";
import Loader from "../../component/Loader";
import Accepted from "../../component/Modal/Accepted";
import DeleteModal from "../../component/Modal/Delete";
import { variants } from "../../constant/Variants";
import { FetchAccountDeletionList } from "./UserListRequestSlice";
import Paginations from "../../component/Pagination";

const UserList = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.accountDeletionRequest.list);
  const total = useSelector((state) => state.accountDeletionRequest.total);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [status, setStatus] = useState("");
  const [tabName, setTabName] = useState("pending");
  const [actionName, setActionName] = useState("");
  const [type, setType] = useState("user");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(
      FetchAccountDeletionList({
        page: page,
        limit: 10,
        search: search,
        type,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, tabName, search, type, page]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getList();
    }

    return () => {
      isRendered = false;
    };
  }, [getList]);


  const handlePage = (val) => {
    setPage(val);
  };

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img
                  src="assets/images/account-deletion-request-selected.png"
                  alt=""
                />
                Anonymized {type} {total}
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 d-flex justify-content-center">
            <ul className="nav nav-pills group-nav mb-3 mt-0 ">
              <li className="nav-item">
                <button
                  className={type === "user" ? "nav-link active" : "nav-link"}
                  type="button"
                  onClick={() => {
                    setType("user");
                    setPage(1);
                  }}
                >
                  User
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    type === "student" ? "nav-link active" : "nav-link"
                  }
                  type="button"
                  onClick={() => {
                    setType("student");
                    setPage(1);
                  }}
                >
                  Student
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12">
            <div className="tab-content" id="pills-tabContent">
              <div
                className={
                  tabName === "pending"
                    ? "tab-pane fade show active"
                    : "tab-pane fade "
                }
                id="pills-pending"
                role="tabpanel"
                aria-labelledby="pills-pending-tab"
              >
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Date</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={6}>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {list?.length > 0 ? (
                            list?.map((item, i) => {
                              return (
                                <tr key={i} variants={variants.card}>
                                  <td>{item?.id}</td>
                                  <td>
                                    {type === "user"
                                      ? `${item?.first_name} ${item?.last_name}`
                                      : type === "student"
                                      ? `${item?.first_name} ${item?.last_name}`
                                      : "NA"}
                                  </td>
                                  <td>
                                    {item?.deletedAt
                                      ? moment(item?.deletedAt).format(
                                          "DD MMM YYYY"
                                        )
                                      : "NA"}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={3}>No data found.</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {list?.length > 0 && (
              <Paginations
                handlePage={handlePage}
                page={page}
                total={total && total}
              />
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

export default UserList;
