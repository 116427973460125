import { Route, Routes, useLocation } from "react-router-dom";
import Login from "../features/login/Login";
import ForgetPassword from "../features/login/ForgetPassword";
import OtpVerification from "./../features/login/OtpVerification";
import ResetPassword from "./../features/login/ResetPassword";
import PrivateRoute from "./PrivateRoute";
import User from "../features/user/User";
import Sidebar from "../component/Sidebar";
import Teacher from "../features/teacher/Teacher";
import ViewUser from "../features/user/ViewUser";
import Adduser from "../features/user/Adduser";
import WalletHistory from "../features/user/WalletHistory";
import Events from "../features/event/Events";
import ViewEvent from "../features/event/ViewEvent";
import ViewTeacher from "../features/teacher/ViewTeacher";
import AddTeacher from "../features/teacher/AddTeacher";
import EditTeacher from "../features/teacher/EditTeacher";
import AddEvent from "../features/event/AddEvent";
import Membership from "../features/membership/Membership";
import ViewMembership from "../features/membership/ViewMembership";
import AddMembership from "../features/membership/AddMembership";
import Class from "../features/class/Class";
import InactiveClass from "../features/class/InactiveClass";
import AddClass from "../features/class/AddClass";
import Register from "../features/register/Register";
import ViewRegister from "../features/register/ViewRegister";
import AddRegister from "../features/register/AddRegister";
import EditRegister from "../features/register/EditRegister";
import Booking from "../features/booking/Booking";
import BookingDetail from "../features/booking/BookingDetail";
import { AnimatePresence } from "framer-motion";
import { useLayoutEffect } from "react";
import AccountDeletion from "../features/accountDeleteionRequest/AccountDeletion";
import ViewAccountDeletion from "../features/accountDeleteionRequest/ViewAccountDeletion";
import AnonymizedUser from "../features/anonymizedUser/UserList";
import ViewImportExport from "../features/importExportData/ViewImportExport";
import SchoolList from "../features/school/SchoolList";
import AddSchool from "../features/school/AddSchool";
import SchoolDetail from "../features/school/SchoolDetail";
import ClassDetail from "../features/class/ClassDetail";
import AreaCodeList from "../features/areacode/AreaCodeList";
import AddAreaCode from "../features/areacode/AddAreaCode";
import Student from "../features/student/Student";
import ViewStudent from "../features/student/ViewStudent";
import EventBooking from "../features/booking/EventBookings";
import Sizes from "../features/sizes/Sizes";
import AddSize from "../features/sizes/AddSize";
import EarningByMembership from "../features/payment/EarningByMembership";
import EarningByTeacher from "../features/payment/EarningByTeacher";
import Refund from "../features/payment/Refund";
import AddPaymentDetail from "../features/payment/AddPaymentDetail";
import AddAccount from "../features/payment/AddAccount";
import TeacherEarningDetails from "../features/payment/TeacherEarningDetails";
import EventTypes from "../features/eventType/EventTypes";
import AddEventType from "../features/eventType/AddEventType";
import Discipline from "../features/discipline/Discipline";
import AddDiscipline from "../features/discipline/AddDiscipline";
import ManageSubAdmin from "./../features/subAdmin/ManageSubAdmin";
import AddSubadmin from "../features/subAdmin/AddSubadmin";
import ViewSubadmin from "./../features/subAdmin/ViewSubadmin";
import ChangePassword from "../features/login/ChangePassword";
import Settings from "../features/settings/SettingsForm";
import EditStudent from "../features/student/EditStudent";
import Wallet from "../features/user/Wallet";
import CancelledClass from "../features/class/CancelledClass";
import AddStudent from "../features/student/AddStudent";
import StudentToTeacher from "../features/student/StudentToTeacher";
import CancelledEarning from "../features/payment/CancelledEarning";
import Consent from "../features/consent/Consent";
import AddConsent from "../features/consent/AddConsent";
import EditConsent from "../features/consent/EditConsent";
import TNCList from "../features/tnc/TNCList";
import AddTnc from "../features/tnc/AddTnc";
import EditRequest from "../features/editRequest/EditRequest";
import EditUserRequest from "../features/editRequest/user/EditUserRequest";
import EditUserConsentRequest from "../features/editRequest/user/EditUserConsentRequest";
import NewsLetter from "../features/newsletter/NewsLetter";
import NewsletterDetail from "../features/newsletter/NewsletterDetail";
import AddNewsletter from "../features/newsletter/AddNewsletter";
import ImportExportStudent from "./../features/importExportData/ImportExportStudent";
import ImportExportUser from "./../features/importExportData/ImportExportUser";
import AllStudentListings from "../features/register/AllStudentListings";
import { useEffect } from "react";
import MailingList from "../features/mailing/MailingList";
import SmsListing from "../features/manageSMS/SmsListing";
import AddSMS from "../features/manageSMS/AddSMS";
import SmsDetails from "../features/manageSMS/SmsDetails";
const Routers = [
  {
    path: "/users",
    element: User,
    hidden: false,
    name: "users",
    subname: "user",
    inactiveImage: "assets/images/users.png",
    activeImage: "assets/images/users-selected.png",
  },
  {
    path: "/user/:id",
    element: ViewUser,
    hidden: true,
    name: "users",
    subname: "user",
  },

  {
    path: "/add-user",
    element: Adduser,
    hidden: true,
    name: "users",
    subname: "user",
  },
  {
    path: "/edit-user/:id",
    element: Adduser,
    hidden: true,
    name: "users",
    subname: "user",
  },
  {
    path: "/wallet-history/:id",
    element: WalletHistory,
    hidden: true,
    name: "users",
    subname: "user",
  },
  {
    path: "/student",
    element: Student,
    hidden: true,
    name: "student",
    subname: "student",
  },
  {
    path: "/add-student",
    element: AddStudent,
    hidden: true,
    name: "student",
    subname: "student",
  },
  {
    path: "/student/:id",
    element: ViewStudent,
    hidden: true,
    name: "student",
    subname: "student",
  },
  {
    path: "/student-to-teacher/:id",
    element: StudentToTeacher,
    hidden: true,
    name: "student",
    subname: "student",
  },
  {
    path: "/teacher",
    element: Teacher,
    hidden: false,
    name: "teachers",
    subname: "teacher",
    inactiveImage: "assets/images/teachers.png",
    activeImage: "assets/images/teachers-selected.png",
  },
  {
    path: "/add-teacher",
    element: AddTeacher,
    hidden: true,
    name: "teachers",
    subname: "teacher",
  },
  {
    path: "/teacher/:id",
    element: ViewTeacher,
    hidden: true,
    name: "teachers",
    subname: "teacher",
  },
  {
    path: "/edit-teacher/:id",
    element: EditTeacher,
    hidden: true,
    name: "teachers",
    subname: "teacher",
  },
  {
    path: "/area-code",
    element: AreaCodeList,
    hidden: false,
    name: "Area",
    subname: "area",
    inactiveImage: "assets/images/AreaUnselected.png",
    activeImage: "assets/images/Area.png",
  },
  {
    path: "/add/area-code",
    element: AddAreaCode,
    hidden: true,
    name: "Area",
    subname: "area",
  },
  {
    path: "/edit/area-code/:id",
    element: AddAreaCode,
    hidden: true,
    name: "Area",
    subname: "area",
  },
  {
    path: "/school",
    element: SchoolList,
    hidden: false,
    name: "Venues",
    subname: "school",
    activeImage: "assets/images/venue.png",
    inactiveImage: "assets/images/VenueUnselected.png",
  },
  {
    path: "/add-school",
    element: AddSchool,
    hidden: true,
    name: "Venues",
    subname: "school",
  },
  {
    path: "/edit-school/:id",
    element: AddSchool,
    hidden: true,
    name: "Venues",
    subname: "school",
  },
  {
    path: "/view-school/:id",
    element: SchoolDetail,
    hidden: true,
    name: "Venues",
    subname: "school",
  },
  {
    path: "/discipline",
    element: Discipline,
    hidden: false,
    name: "discipline",
    subname: "discipline",
    inactiveImage: "assets/images/disciplineMenu.png",
    activeImage: "assets/images/disciplineSelected.png",
  },
  {
    path: "/add-discipline",
    element: AddDiscipline,
    hidden: true,
    name: "discipline",
    subname: "discipline",
  },
  {
    path: "/edit/discipline/:id",
    element: AddDiscipline,
    hidden: true,
    name: "discipline",
    subname: "discipline",
  },
  {
    path: "/event-bookings",
    element: EventBooking,

    hidden: true,
    name: "event-bookings",
    subname: "booking",
  },
  {
    path: "/event-types",
    element: EventTypes,
    hidden: false,
    name: "event types",
    subname: "types",
    inactiveImage: "assets/images/event-calender.png",
    activeImage: "assets/images/event-calender-selected.png",
  },

  {
    path: "/add-event-type",
    element: AddEventType,
    hidden: true,
    name: "event types",
    subname: "types",
  },
  {
    path: "/edit/event-type/:id",
    element: AddEventType,
    hidden: true,
    name: "event types",
    subname: "types",
  },
  {
    path: "/events",
    element: Events,
    hidden: false,
    name: "events",
    subname: "event",
    inactiveImage: "assets/images/event-calender.png",
    activeImage: "assets/images/event-calender-selected.png",
  },
  {
    path: "/add-event",
    element: AddEvent,
    hidden: true,
    name: "events",
    subname: "event",
  },
  {
    path: "/duplicate-event/:id",
    element: AddEvent,
    hidden: true,
    name: "events",
    subname: "event",
  },
  {
    path: "/edit-event/:id",
    element: AddEvent,
    hidden: true,
    name: "events",
    subname: "event",
  },
  {
    path: "/event/:id",
    element: ViewEvent,
    hidden: true,
    name: "events",
    subname: "event",
  },
  {
    path: "/sizes",
    element: Sizes,
    hidden: true,
    name: "size",
    subname: "size",
  },
  {
    path: "/add-size",
    element: AddSize,
    hidden: true,
    name: "events",
    subname: "event",
  },
  {
    path: "/edit-size/:id",
    element: AddSize,
    hidden: true,
    name: "events",
    subname: "event",
  },
  {
    path: "/membership",
    element: Membership,
    hidden: false,
    name: "member levels",
    subname: "membership",
    inactiveImage: "assets/images/memberships.png",
    activeImage: "assets/images/memberships-selected.png",
  },
  {
    path: "/membership/:id",
    element: ViewMembership,
    name: "memberships",
    subname: "membership",
    hidden: true,
  },

  {
    path: "/add-membership",
    element: AddMembership,
    hidden: true,
    name: "memberships",
    subname: "membership",
  },
  {
    path: "/edit-membership/:id",
    element: AddMembership,
    hidden: true,
    name: "memberships",
    subname: "membership",
  },
  {
    path: "/class",
    element: Class,
    hidden: false,
    name: "Classes",
    subname: "class",
    inactiveImage: "assets/images/class-schedules.png",
    activeImage: "assets/images/class-schedules-selected.png",
  },
  {
    path: "/class/inactive",
    element: InactiveClass,
    hidden: true,
    name: "Classes",
    subname: "class",
    inactiveImage: "assets/images/class-schedules.png",
    activeImage: "assets/images/class-schedules-selected.png",
  },
  {
    path: "/cancelled-class",
    element: CancelledClass,
    hidden: true,
    name: "Classes",
    subname: "class",
    inactiveImage: "assets/images/class-schedules.png",
    activeImage: "assets/images/class-schedules-selected.png",
  },
  {
    path: "/add-class",
    element: AddClass,
    hidden: true,
    name: "Classes",
    subname: "class",
  },
  {
    path: "/edit-class/:sid/:id",
    element: AddClass,
    hidden: true,
    name: "Classes",
    subname: "class",
  },
  {
    path: "/class-detail/:id",
    element: ClassDetail,
    hidden: true,
    name: "Classes",
    subname: "class",
  },
  {
    path: "/register",
    element: Register,
    hidden: false,
    name: "register",
    inactiveImage: "assets/images/register.png",
    activeImage: "assets/images/register-selected.png",
    subname: "register",
  },
  {
    path: "/register/:id",
    element: ViewRegister,
    hidden: true,
    name: "register",
    subname: "register",
  },
  {
    path: "/add-register",
    element: AddRegister,
    hidden: true,
    name: "register",
    subname: "register",
  },
  {
    path: "/edit-register/:id",
    element: EditRegister,
    hidden: true,
    name: "Register",
    subname: "register",
  },
  {
    path: "/bookings",
    element: Booking,
    hidden: false,
    name: "Bookings",
    subname: "booking",
    inactiveImage: "assets/images/bookings.png",
    activeImage: "assets/images/bookings-selected.png",
  },
  {
    path: "/booking/:id",
    element: BookingDetail,
    hidden: true,
    name: "Bookings",
    subname: "booking",
  },
  {
    path: "/event-booking/:id",
    element: BookingDetail,
    hidden: true,
    name: "Bookings",
    subname: "booking",
  },
  {
    path: "/earnings-by-staff",
    element: EarningByTeacher,
    hidden: false,
    name: "Earnings",
    subname: "earning",
    inactiveImage: "assets/images/payment-refund.png",
    activeImage: "assets/images/payment-refund-selected.png",
  },
  {
    path: "/account-deletion-request",
    element: AccountDeletion,
    hidden: false,
    name: "account & deletion request",
    subname: "deletion",
    inactiveImage: "assets/images/account-deletion-request.png",
    activeImage: "assets/images/account-deletion-request-selected.png",
  },
  {
    path: "/anonymized-users",
    element: AnonymizedUser,
    hidden: false,
    name: "Anonymized Users",
    subname: "deletion",
    inactiveImage: "assets/images/account-deletion-request.png",
    activeImage: "assets/images/account-deletion-request-selected.png",
  },
  {
    path: "/view-account-deletion/:id",
    element: ViewAccountDeletion,
    hidden: true,
    name: "deletion",
    subname: "deletion",
  },
  {
    path: "/import-export-data/user",
    element: ImportExportUser,
    hidden: false,
    name: "Imp/Exp Data",
    subname: "export",
    inactiveImage: "assets/images/import-export-data.png",
    activeImage: "assets/images/import-export-data-selected.png",
  },
  {
    path: "/import-export-data/student",
    element: ImportExportStudent,
    hidden: true,
    name: "Imp/Exp Data",
    subname: "export",
    inactiveImage: "assets/images/import-export-data.png",
    activeImage: "assets/images/import-export-data-selected.png",
  },
  {
    path: "/import-export-data/:id",
    element: ViewImportExport,
    hidden: true,
    name: "Imp/Exp Data",
    subname: "export",
  },

  {
    path: "/earnings-by-staff/:id",
    element: TeacherEarningDetails,
    hidden: true,
    name: "earning",
    subname: "earning",
    inactiveImage: "assets/images/payment-refund.png",
    activeImage: "assets/images/payment-refund-selected.png",
  },
  {
    path: "/cancelled-earnings-by-staff/:id",
    element: CancelledEarning,
    hidden: true,
    name: "earning",
    subname: "earning",
    inactiveImage: "assets/images/payment-refund.png",
    activeImage: "assets/images/payment-refund-selected.png",
  },
  {
    path: "/earnings-by-membership",
    element: EarningByMembership,
    hidden: true,
    name: "earning",
    subname: "earning",
  },
  {
    path: "/refund",
    element: Refund,
    hidden: true,
    name: "earning",
    subname: "refund",
  },
  {
    path: "/add/payment-detail",
    element: AddPaymentDetail,
    hidden: true,
    name: "earning",
    subname: "payment",
  },
  {
    path: "/add/account",
    element: AddAccount,
    hidden: true,
    name: "earning",
    subname: "payment",
  },
  {
    path: "/mailing-list",
    element: MailingList,
    hidden: false,
    name: "Subscribed User",
    subname: "mailing",
    inactiveImage: "assets/images/users.png",
    activeImage: "assets/images/users-selected.png",
  },
  {
    path: "/sms",
    element: SmsListing,
    hidden: false,
    name: "SMS",
    subname: "sms",
    inactiveImage: "assets/images/users.png",
    activeImage: "assets/images/users-selected.png",
  },
  {
    path: "/sms/:id",
    element: SmsDetails,
    hidden: true,
    name: "SMS",
    subname: "sms",
    inactiveImage: "assets/images/users.png",
    activeImage: "assets/images/users-selected.png",
  },
  {
    path: "/add/sms",
    element: AddSMS,
    hidden: true,
    name: "SMS",
    subname: "sms",
    inactiveImage: "assets/images/users.png",
    activeImage: "assets/images/users-selected.png",
  },
  {
    path: "/edit/sms/:id",
    element: AddSMS,
    hidden: true,
    name: "SMS",
    subname: "sms",
    inactiveImage: "assets/images/users.png",
    activeImage: "assets/images/users-selected.png",
  },
  {
    path: "/newsletter",
    element: NewsLetter,
    hidden: false,
    name: "Eflyer",
    subname: "newsletter",
    inactiveImage: "assets/images/newsletter.png",
    activeImage: "assets/images/newsletter-selected.png",
  },
  {
    path: "/newsletter/:id",
    element: NewsletterDetail,
    hidden: true,
    name: "newsletter",
    subname: "newsletter",
    inactiveImage: "assets/images/newsletter.png",
    activeImage: "assets/images/newsletter-selected.png",
  },
  {
    path: "/add/newsletter",
    element: AddNewsletter,
    hidden: true,
    name: "newsletter",
    subname: "newsletter",
    inactiveImage: "assets/images/newsletter.png",
    activeImage: "assets/images/newsletter-selected.png",
  },
  {
    path: "/edit/newsletter/:id",
    element: AddNewsletter,
    hidden: true,
    name: "newsletter",
    subname: "newsletter",
    inactiveImage: "assets/images/newsletter.png",
    activeImage: "assets/images/newsletter-selected.png",
  },
  // {
  //   path: "/grading-downloads",
  //   element: null,
  //   hidden: false,
  //   name: "grading",
  //   subname: "grading",
  //   inactiveImage: "assets/images/grading-downloads.png",
  //   activeImage: "assets/images/grading-downloads-selected.png",
  // },
  // {
  //   path: "/events",
  //   element: null,
  //   hidden: false,
  //   name: "event",
  //   subname: "event",
  //   inactiveImage: "assets/images/events.png",
  //   activeImage: "assets/images/events-selected.png",
  // },
  {
    path: "/consents",
    element: Consent,
    hidden: false,
    name: "consents",
    subname: "consents",
    inactiveImage: "assets/images/payment-refund.png",
    activeImage: "assets/images/payment-refund-selected.png",
  },
  {
    path: "/add-consent",
    element: AddConsent,
    hidden: true,
    name: "consents",
    subname: "consents",
    inactiveImage: "assets/images/payment-refund.png",
    activeImage: "assets/images/payment-refund-selected.png",
  },
  {
    path: "/edit/consent/:id",
    element: EditConsent,
    hidden: true,
    name: "consents",
    subname: "consents",
    inactiveImage: "assets/images/payment-refund.png",
    activeImage: "assets/images/payment-refund-selected.png",
  },
  {
    path: "/notification",
    element: null,
    hidden: false,
    name: "notification",
    subname: "notification",
    inactiveImage: "assets/images/notification.png",
    activeImage: "assets/images/notification-selected.png",
  },
  {
    path: "/sub-admin",
    element: ManageSubAdmin,
    hidden: false,
    name: "sub-admin",
    subname: "sub-admin",
    inactiveImage: "assets/images/users.png",
    activeImage: "assets/images/users-selected.png",
  },
  {
    path: "/sub-admin/:id",
    element: ViewSubadmin,
    hidden: true,
    name: "sub-admin",
    subname: "user",
  },

  {
    path: "/add-sub-admin",
    element: AddSubadmin,
    hidden: true,
    name: "sub-admin",
    subname: "sub-admin",
  },
  {
    path: "/edit-sub-admin/:id",
    element: AddSubadmin,
    hidden: true,
    name: "sub-admin",
    subname: "sub-admin",
  },
  {
    path: "/edit-request",
    element: EditRequest,
    hidden: false,
    name: "edit request",
    subname: "edit-request",
    inactiveImage: "assets/images/tncUnselected.png",
    activeImage: "assets/images/tncSelected.png",
  },
  {
    path: "/user-edit-request",
    element: EditUserRequest,
    hidden: true,
    name: "edit request",
    subname: "edit-request",
    inactiveImage: "assets/images/tncUnselected.png",
    activeImage: "assets/images/tncSelected.png",
  },
  {
    path: "/user-consent-edit-request",
    element: EditUserConsentRequest,
    hidden: true,
    name: "edit request",
    subname: "edit-request",
    inactiveImage: "assets/images/tncUnselected.png",
    activeImage: "assets/images/tncSelected.png",
  },
  {
    path: "/change-password",
    element: ChangePassword,
    hidden: true,
    name: "change-password",
    subname: "change-password",
  },
  {
    path: "/settings",
    element: Settings,
    hidden: true,
    name: "settings",
    subname: "settings",
  },
  {
    path: "/edit-student/:id",
    element: EditStudent,
    hidden: true,
    name: "change-password",
    subname: "change-password",
  },
  {
    path: "/user/:id/wallet/:amt",
    element: Wallet,
    hidden: true,
    name: "change-password",
    subname: "change-password",
  },
  {
    path: "/terms-and-conditions",
    element: TNCList,
    hidden: false,
    name: "terms and conditions",
    subname: "terms-and-conditions",
    inactiveImage: "assets/images/tncUnselected.png",
    activeImage: "assets/images/tncSelected.png",
  },
  {
    path: "/add/terms-and-conditions",
    element: AddTnc,
    hidden: true,
    name: "Terms and Conditions",
    subname: "Terms and Conditions",
    inactiveImage: "assets/images/tncUnselected.png",
    activeImage: "assets/images/tncSelected.png",
  },
  {
    path: "/edit/terms-and-conditions/:id",
    element: AddTnc,
    hidden: true,
    name: "terms-and-conditions",
    subname: "terms-and-conditions",
    inactiveImage: "assets/images/tncUnselected.png",
    activeImage: "assets/images/tncSelected.png",
  },
  {
    path: "/registered-members/:id/:name",
    element: AllStudentListings,
    hidden: true,
    name: "register",
    subname: "register",
  },
];

export default Routers;

export function RenderRoutes({ routers }) {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  function clearConsole() {
    if (window.console || window.console.firebug) {
      console.clear();
    }
  }

  // useEffect(() => {
  //   if (location.pathname) {
  //     clearConsole();
  //   }
  // }, [location.pathname]);
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Login />} />

          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/otp/:email" element={<OtpVerification />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {routers.map((route, i) => {
            return (
              <Route
                key={i}
                path={route.path}
                element={
                  <PrivateRoute>
                    <div className="admin-panel">
                      <div className="container-fluid">
                        <div className="row no-gutters">
                          <Sidebar />

                          {route?.element && <route.element />}
                        </div>
                      </div>
                    </div>
                  </PrivateRoute>
                }
              ></Route>
            );
          })}
        </Routes>
      </AnimatePresence>
    </>
  );
}
