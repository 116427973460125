import { getData, getDataforUrl, postFormData, putFormData } from "./index";

export const LoginApi = async (payload) => {
  return getData("admin/login", payload).then((data) => {
    return data;
  });
};

export const changePassword = async (payload) => {
  return putFormData("admin/changePassword", payload).then((data) => {
    return data;
  });
};

export const resetPassword = async (payload) => {
  return putFormData("admin/resetPassword", payload).then((data) => {
    return data;
  });
};

export const forgotPassword = async (payload) => {
  return getData("admin/forgotpassword", payload).then((data) => {
    return data;
  });
};

export const resendOtp = async (payload) => {
  return getData("admin/resendOtp", payload).then((data) => {
    return data;
  });
};
export const verifyOtp = async (payload) => {
  return getData("admin/verifyOtp", payload).then((data) => {
    return data;
  });
};
export const settingsFormApi = async (payload) => {
  return putFormData("admin/updateSettings", payload).then((data) => {
    return data;
  });
};

