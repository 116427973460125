import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Loader";
import BackButton from "./../../component/BackButton";
import { Modal } from "react-bootstrap";
import { FetchStudentDetails, details } from "./studentslice";
import DeleteModal from "./../../component/Modal/Delete";
import { deleteData } from "../../api";
import { toast } from "react-toastify";

function ViewStudent() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const data = useSelector((state) => state.student.detail); 
  const [isDebt, setisDebt] = useState(false);
  const [address, setAddress] = useState(null);
  const [bookingTimeSlotId, setBookingTimeSlotId] = useState("");
  const [bookedclassList, setBookedClassList] = useState([]);
  const [bookedEventList, setBookedEventList] = useState([]);
  const [debtList, setDebtList] = useState([]);
  const [showCancelBooking, setShowCancelBooking] = useState(false);
  const [delFor, setDelFor] = useState("");
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const getDetails = useCallback(() => {
    dispatch(FetchStudentDetails(id)).then((res) => {
      if (res?.payload.code === 1) {
        setAddress(res?.payload?.data?.parent_address_details);
        setBookedClassList(res?.payload?.data?.class_booking_list);
        setBookedEventList(res?.payload?.data?.event_booking_list);
        setDebtList(res?.payload?.data?.debt_list);
      }
    });
  }, [dispatch, id]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
    }


    return () => {
      isRendered = false;
      dispatch(details(null));
    };
  }, [getDetails]);

  useEffect(() => {
    setisDebt(Number(data?.parent_details_with_consent?.wallet) < 0);
  }, [data]);

  const confirmCancelBooking = () => {
    setSubmitting(true);
    deleteData("admin/removeStudentFromBooking", {
      studentId: id,
      bookingTimeSlotId: delFor === "class" ? bookingTimeSlotId : "",
      bookingEventId: delFor === "event" ? bookingTimeSlotId : "",
    }).then((res) => {
      if (res?.code === 1) {
        setSubmitting(false);

        toast?.success(res?.message);
        getDetails();
        setShowCancelBooking(false);
      } else {
        toast?.error(res?.message);
      }
    });
  };

  if (!!!data) {
    return (
      <article className="col-lg-9 articleClass">
        <Loader />
      </article>
    );
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>Student Details</h3>
            </div>
          </div>
          <div className="row mt-3 px-md-5">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <div className="accordion accordion-flush" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <h3>Account Holder Details</h3>
                      <button
                        type="button"
                        className="btn btn-save p-3 fs-6"
                        style={{ marginLeft: "18px" }}
                        onClick={() => {
                          navigate(`/user/${id}/wallet/${data?.parent_details_with_consent?.wallet}`);
                        }}
                      >
                        Wallet <i class="fa fa-gbp" aria-hidden="true"></i>{" "}
                        {data?.parent_details_with_consent?.wallet}
                      </button>
                    </button>
            
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className=" px-5 py-3 text-capitalize position-relative h-100">
                            <p className="mb-1">full name</p>
                            <h6 className="">
                              <img
                                src="assets/images/First-Name.png"
                                className="detail-icon"
                                alt=""
                              />
                              {data?.parent_details_with_consent?.first_name
                                ? data?.parent_details_with_consent
                                    ?.first_name +
                                  " " +
                                  data?.parent_details_with_consent?.last_name
                                : ""}
                            </h6>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className=" px-5 py-3 text-capitalize position-relative h-100">
                            <p className="mb-1">username</p>
                            <h6 className="">
                              <img
                                src="assets/images/First-Name.png"
                                className="detail-icon"
                                alt=""
                              />
                              {data?.parent_details_with_consent?.username ||
                                "NA"}
                            </h6>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className=" px-5 py-3 text-capitalize position-relative h-100">
                            <p className="mb-1">email ID</p>
                            <h6 className="text-break">
                              <img
                                src="assets/images/Email-Address.png"
                                className="detail-icon"
                                alt=""
                              />
                              {data?.parent_details_with_consent?.email || "NA"}
                            </h6>
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                            <p className="mb-1">full address</p>
                            <h6 className="">
                              <img
                                src="assets/images/address.png"
                                className="detail-icon"
                                alt=""
                              />
                              {address
                                ? `${address?.property_number}, ${
                                    address?.property_name
                                      ? address?.property_name + ", "
                                      : ", "
                                  } ${address?.street_number}, ${
                                    address?.town
                                  }, ${address?.country}-${
                                    address?.postal_code
                                  }`
                                : "NA"}
                            </h6>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                            <p className="mb-1">contact number</p>
                            <h6 className="">
                              <img
                                src="assets/images/mobile.png"
                                className="detail-icon"
                                alt=""
                              />{" "}
                              {address?.mobile
                                ? `${address?.countryCode}${address?.mobile}`
                                : "NA"}
                            </h6>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                          <div
                            className="bg-white px-5 py-3  position-relative h-100"
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            <h5 className="mb-1">
                              {data?.parent_details_with_consent?.consent
                                ?.length > 1
                                ? "Consents"
                                : "Consent"}
                            </h5>
                            {data?.parent_details_with_consent?.consent
                              ?.length > 0
                              ? data?.parent_details_with_consent?.consent?.map(
                                  (item, i) => {
                                    return (
                                      <>
                                        <p>
                                          <span>
                                            {item?.consent_details?.label}:{" "}
                                          </span>
                                          <span className="fw-bold">
                                            {item?.value}
                                          </span>
                                        </p>
                                        {item?.consent_details?.id === 6 &&
                                          item?.value?.toLowerCase() ===
                                            "yes" && (
                                            <p>
                                              <span>Medication: </span>
                                              <span className="fw-bold">
                                                {item?.note}
                                              </span>
                                            </p>
                                          )}
                                      </>
                                    );
                                  }
                                )
                              : "No Consent Added."}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-sm-12 mb-3">
              <h3>Account Holder Details</h3>
            </div> */}

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <hr />
            </div>

            <div className="col-sm-12 mb-3">
              <h3>Student Details</h3>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">full name</p>
                  <h6 className="">
                    <img
                      src="assets/images/First-Name.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.first_name
                      ? data?.first_name + " " + data?.last_name
                      : "NA"}
                  </h6>
                </div>
              </div>

              {/* <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                      <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                        <p className="mb-1">full address</p>
                        <h6 className="">
                          <img
                            src="assets/images/address.png"
                            className="detail-icon"
                            alt=""
                          />
                          {data?.address || "NA"}
                        </h6>
                      </div>
                    </div> */}
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">membership level</p>
                  <h6 className="">
                    <img
                      src="assets/images/membership-status.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.membership_details
                      ? data?.membership_details?.membership_details?.name
                      : data?.trialAccountStatus === "1"
                      ? "Trial"
                      : data?.trialAccountStatus === "2"
                      ? "Trial Membership Expired. Kindly upgrade."
                      : !data?.membership_details
                      ? "No membership. Kindly purchase membership."
                      : "Membership expired. Kindly upgrade."}
                  </h6>
                </div>
              </div>
              {/* <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">membership expiry</p>
                  <h6 className="">
                    <img
                      src="assets/images/Date-of-Birth.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.membership_details?.valid_till || "NA"}
                  </h6>
                </div>
              </div> */}

              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">data of birth</p>
                  <h6 className="">
                    <img
                      src="assets/images/Date-of-Birth.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.dob && data?.dob !== "0000-00-00"
                      ? moment(data?.dob).format("DD MMM YYYY")
                      : data?.dob_year
                      ? moment(
                          `${data?.dob_year}-${data?.dob_month}-${data?.dob_day}`
                        ).format("DD MMM YYYY")
                      : "NA"}
                  </h6>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">discipline</p>
                  <h6 className="">
                    <img
                      src="assets/images/discipline.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.discipline_details
                      ? data?.discipline_details?.name
                      : "NA"}
                  </h6>
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">Emergency Contact Number</p>
                  <h6 className="">
                    <img
                      src="assets/images/address.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.emergency_contactNo &&
                    data?.emergency_contactNo !== "null" &&
                    data?.emergency_contactNo !== "undefined"
                      ? data?.emergency_countryCode + data?.emergency_contactNo
                      : "NA"}
                  </h6>
                </div>
              </div>
              {data?.membership_details && (
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                  <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                    <p className="mb-1">next renewal date</p>
                    <h6 className="">
                      <img
                        src="assets/images/membership-status.png"
                        className="detail-icon"
                        alt=""
                      />
                      {moment(data?.membership_details?.valid_till).format(
                        "DD MMM YYYY"
                      )}
                    </h6>
                  </div>
                </div>
              )}
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">school venue</p>
                  <h6 className="">
                    <img
                      src="assets/images/address.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.school_details
                      ? `${data?.school_details?.name}, ${data?.school_details?.areacode_details?.areacode}`
                      : ""}
                  </h6>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">medical note</p>
                  <h6 className="">
                    <img
                      src="assets/images/medical.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.medical_note || "NA"}
                  </h6>
                </div>
              </div>
            </div>

            {(bookedEventList?.length > 0 || bookedclassList?.length > 0) && (
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                <div
                  className="accordion accordion-flush"
                  id="accordionExample2"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        <h3>Bookings</h3>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample2"
                    >
                      <div className="accordion-body">
                        {bookedclassList?.length > 0 && (
                          <div className="row mb-4">
                            <label className="theme-color fw-bold">
                              Class Bookings
                            </label>
                            {bookedclassList?.map((item, i) => {
                              return (
                                <div className="col-sm-12 col-md-6 col-lg-4 mt-3 ">
                                  <div
                                    className="card mb-3 shadow p-4"
                                    style={{
                                      height: "100%",
                                    }}
                                  >
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Class Name:{" "}
                                      </label>{" "}
                                      <span>
                                        {
                                          item?.booking_classes?.class_details
                                            ?.name
                                        }
                                      </span>
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Class Type:{" "}
                                      </label>{" "}
                                      <span>
                                        {
                                          item?.booking_classes?.class_details
                                            ?.class_type
                                        }
                                      </span>
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Class Date:{" "}
                                      </label>{" "}
                                      <span>
                                        {item?.date
                                          ? `${moment(item?.date).format(
                                              "DD MMM YYYY"
                                            )}`
                                          : "NA"}
                                      </span>
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Class Time:{" "}
                                      </label>{" "}
                                      <span>
                                        {item?.start_time
                                          ? `${moment(
                                              item?.start_time,
                                              "hh:mm:ss"
                                            ).format("hh:mm a")}-${moment(
                                              item?.end_time,
                                              "hh:mm:ss"
                                            ).format("hh:mm a")}`
                                          : "NA"}
                                      </span>
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Venue:{" "}
                                      </label>{" "}
                                      <span>
                                        {item?.booking_classes?.class_details
                                          ?.school_details
                                          ? `${item?.booking_classes?.class_details?.school_details?.name}, ${item?.booking_classes?.class_details?.school_details?.address}`
                                          : "NA"}
                                      </span>
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Price:{" "}
                                      </label>{" "}
                                      <span>
                                        &#163;
                                        {data?.student_type === "child"
                                          ? `${item?.booking_classes?.child_price}`
                                          : data?.student_type === "adult"
                                          ? `${item?.booking_classes?.adult_price}`
                                          : "NA"}
                                      </span>
                                    </p>
                                    <p className="mb-0 mt-1">
                                      <button
                                        className="btn btn-save p-1 fs-6"
                                        onClick={() => {
                                          let ids = item?.student_list?.find(
                                            (it) =>
                                              it?.studentId?.toString() ===
                                              id?.toString()
                                          )?.bookingTimeSlotId;
                                          setBookingTimeSlotId(ids);
                                          setDelFor("class");
                                          setShowCancelBooking(true);
                                        }}
                                        disabled={submitting}
                                      >
                                        Cancel Booking
                                      </button>
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {bookedEventList?.length > 0 && (
                          <div className="row">
                            <label className="theme-color fw-bold">
                              Event Bookings
                            </label>
                            {bookedEventList?.map((item, i) => {
                              return (
                                <div className="col-sm-12 col-md-6 col-lg-4 ">
                                  <div
                                    className="card mb-3 shadow p-4"
                                    style={{
                                      height: "300px",
                                    }}
                                  >
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Event Name:{" "}
                                      </label>{" "}
                                      <span>{item?.event_details?.name}</span>
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Event Type:{" "}
                                      </label>{" "}
                                      <span>
                                        {item?.age_group?.toLowerCase() ===
                                        "both"
                                          ? "Adult | Child"
                                          : item?.age_group}
                                      </span>
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Event Date:{" "}
                                      </label>{" "}
                                      <span>
                                        {item?.date
                                          ? `${moment(item?.date).format(
                                              "DD MMM YYYY"
                                            )}`
                                          : "NA"}
                                      </span>
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Event Time:{" "}
                                      </label>{" "}
                                      <span>
                                        {item?.event_details?.start_time
                                          ? `${moment(
                                              item?.event_details?.start_time,
                                              "hh:mm:ss"
                                            ).format("hh:mm a")}`
                                          : "NA"}
                                      </span>
                                    </p>
                                    {item?.level_details && (
                                      <>
                                        <p className="mb-0">
                                          <label className="fw-bold theme-color">
                                            Level Name:{" "}
                                          </label>{" "}
                                          <span>
                                            {item?.level_details?.name
                                              ? item?.level_details?.name
                                              : "NA"}
                                          </span>
                                        </p>
                                        <p className="mb-0">
                                          <label className="fw-bold theme-color">
                                            Level Time:{" "}
                                          </label>{" "}
                                          <span>
                                            {item?.level_details?.time
                                              ? `${moment(
                                                  item?.level_details?.time,
                                                  "hh:mm:ss"
                                                ).format("hh:mm a")}`
                                              : "NA"}
                                          </span>
                                        </p>
                                      </>
                                    )}
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Venue:{" "}
                                      </label>{" "}
                                      <span>
                                        {item?.school_details
                                          ? `${item?.school_details?.name}, ${item?.school_details?.address}`
                                          : "NA"}
                                      </span>
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold theme-color">
                                        Price
                                      </label>{" "}
                                      <span>&#163; {item?.price || "NA"}</span>
                                    </p>
                                    <p className="mb-0 mt-1">
                                      <button
                                        className="btn btn-save p-1 fs-6"
                                        onClick={() => {
                                          let ids = item?.student_list?.find(
                                            (it) =>
                                              it?.studentId?.toString() ===
                                              id?.toString()
                                          )?.bookingEventId;
                                          setDelFor("event");
                                          setBookingTimeSlotId(ids);
                                          setShowCancelBooking(true);
                                        }}
                                        disabled={submitting}
                                      >
                                        Cancel Booking
                                      </button>
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {(debtList?.length > 0) && (
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                <div
                  className="accordion accordion-flush"
                  id="accordionExample3"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        <h3>Debt List</h3>
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample3"
                    >
                      <div className="accordion-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th><label className="theme-color fw-bold">Teacher</label></th>
                            <th><label className="theme-color fw-bold">Booking For</label></th>
                            <th><label className="theme-color fw-bold">Debt Amount</label></th>
                          </tr>
                        </thead>
                        <tbody>
                          {debtList.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.teacher_details?.full_name}</td>
                              <td>{item?.classId ? `CLASS - ${item?.class_details?.name}` : `EVENT - ${item?.event_details?.name}`}</td>
                              <td className="text-red"> - {item?.amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <DeleteModal
          submitting={submitting}
          setSubmitting={setSubmitting}
          show={showCancelBooking}
          handleShow={() => {
            setShowCancelBooking(!showCancelBooking);
          }}
          page="Booking"
          confirmDelete={confirmCancelBooking}
          cancel={() => {
            setShowCancelBooking(false);
          }}
        />

      <Modal show={isDebt} onHide={() => { setisDebt(false); }} size="md" centered >
        <Modal.Body>
         <center>This user has an outstanding debt!!</center>
        </Modal.Body>
      </Modal>
      </article>
    );
  }
}

export default ViewStudent;

