import { Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { settingsFormApi } from "../../api/setting";
import Loader from "../../component/Loader";

function SettingsForm() {
  const [loading, setLoading] = useState(false);
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="form-style mt-5 addFormMw">
          <Formik
            initialValues={{
              booking_fee: localStorage.getItem('booking_fee'),
            }}
            
            onSubmit={(values, { isSubmitting, resetForm }) => {
              setLoading(true);
              settingsFormApi({
                booking_fee: values.booking_fee,
              }).then((res) => {
                setLoading(false);

                if (res?.code === 1) {
                  toast.success(res?.message);
                  localStorage.setItem("booking_fee",values.booking_fee);
                } else {
                  toast.error(res?.message);
                }
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFormikState,
            }) => (
              <form className="row" onSubmit={handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <h2 className="mdl-ttl">Settings</h2>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                <label htmlFor="booking_fee" className="form-label">Booking Fee</label>
                  <div className="input-container">
                    <input
                      type="number"
                      className="form-control ps-4"
                      placeholder="Booking Fee"
                      name="booking_fee"
                      value={values.booking_fee}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <img
                      src="assets/images/price.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                </div>
                
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-save"
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Done"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </article>
  );
}

export default SettingsForm;
