import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CiWallet } from "react-icons/ci";
import { Link, useNavigate , useParams} from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";
import Paginations from "../../component/Pagination";
import { variants } from "../../constant/Variants";
import {
  FetchWalletHistory,
  lists,
  totalWalletCountSelector,
  walletListSelector,
  walletAmountSelector,
} from "./WalletHistorySlice";

function WalletHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const list = useSelector(walletListSelector);
  const total = useSelector(totalWalletCountSelector);
  const wallet_amount = useSelector(walletAmountSelector);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    payment_status: ""
  });
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { id} = useParams();
  const handlePage = (val) => {
    setPage(val);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(FetchWalletHistory({ userId: id, page: page, limit: 10, search: search, payment_status: filters?.payment_status })).then(
      () => {
        setLoading(false);
      }
    );
  }, [dispatch, page, search, filters.payment_status]);

  useEffect(() => {
    getList();
    console.log(getList());
  }, [getList]);

  useEffect(() => {
    return () => {
      dispatch(lists(null));
    };
  }, []);

  return (
    <article className="col-lg-9 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
          <button
                type="button"
                className="btn btn-save p-3 fs-6"
                onClick={() => {
                  navigate(`/user/${id}/wallet/${wallet_amount}`);
                }}
              >
                Wallet <i class="fa fa-gbp" aria-hidden="true"></i>{" "}
                {wallet_amount}
              </button>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search by Payment By, Amount, Transaction ID, Note"
                  value={search}
                  onChange={(e) => {
                    setPage(1);

                    setSearch(e.target.value);
                  }}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="row mt-1">
        <div className="col-sm-12 col-md-12 col-lg-12 float-right mb-2">
            <div className="form-group selectAreaCode">
              <div className="input-container">
                <select
                  className="form-control pl-5 form-select"
                  name="payment_status"
                  onChange={(e) => {
                    setFilters((prev) => {
                      return {
                        ...prev,
                        payment_status: e.target.value,
                      };
                    });
                  }}
                  value={filters?.payment_status}
                >
                  <option value="">Filter By Payment Status</option>
                  <option value="1">Success</option>
                  <option value="0">Failed</option>

                  
                </select>
                <img
                  src="assets/images/event-name.png"
                  className="input-img"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Payment By</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Payment Status</th>
                    <th scope="col">Transaction ID</th>
                    <th scope="col">Note</th>
                    <th scope="col">Created At</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={9}>
                      <Loader />
                    </td>
                  ) : (
                    <>
                      {list?.length > 0 ? (
                        list?.map((item, i) => {
                          return (
                            <tr variants={variants.card} key={i}>
                              <td>{item?.id || "NA"}</td>
                              <td>{item?.payment_by ? item?.payment_by.charAt(0).toUpperCase() + item?.payment_by.slice(1) : "NA"}</td>
                              <td><b> {item?.amount ? item?.amount : "NA"}</b></td>
                              <td >{item?.payment_status == 1 ? <b className='text-success'>Success</b> : <b className='text-danger'>Failed</b>}</td>
                              <td>{item?.transaction_id ? item?.transaction_id : "NA"}</td>
                              <td>{item?.note ? item?.note : "NA"}</td>
                              <td>{moment(item?.date).format("DD MMM YYYY") || "NA"}</td>
                              
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10}>No data found.</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {list?.length > 0 && (
            <Paginations
              handlePage={handlePage}
              page={page}
              total={total && total}
            />
          )}
        </div>
      </div>
    </article>
  );
}

export default WalletHistory;
