import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ClassDetailReducer, clearClassDetail } from "../slice";
import moment from "moment";
import { changeRegisterFor } from "../register/RegisterSlice";
import { useState } from "react";
import Cancel from "../../component/Modal/Cancel";
import { useToggle } from "../../utils/hooks";
import { toast } from "react-toastify";
import { cancelClass } from "../../api/class";
import BackButton from "../../component/BackButton";
import Loader from "../../component/Loader";

function ClassDetail() {
  const { schoolId, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const details = useSelector((state) => state.main.classDetail);
  const [index, setIndex] = useState(0);
  const [show, handleShow] = useToggle(false);
  const [cancelClassDetail, setCancelClassDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false)
  const getDetails = useCallback(() => {
    setLoading(true);
    dispatch(
      ClassDetailReducer({
        id: id,
        schoolId: schoolId,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, id, schoolId]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
    }

    return () => {
      isRendered = false;
    };
  }, [getDetails]);
  const handleCancel = (val) => {
    setSubmitting(true)
    cancelClass({
      classId: id,
      time_slot_id: cancelClassDetail?.time_slot_id,
      date: moment(cancelClassDetail?.date).format("YYYY-MM-DD"),
      cancel_reason: val,
    }).then((res) => {
      setSubmitting(false)
      if (res?.code === 1) {
        toast.success(res?.message);
        handleShow();
        getDetails();
      } else {
        toast.error(res?.message);
      }
    });
  };
  useEffect(() => {
    return () => {
      dispatch(clearClassDetail(null));
    };
  }, [dispatch]);
  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        <Loader />
      </article>
    );
  }
  return (
    <>
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>Class Details</h3>
            </div>
          </div>
          <div className="row mt-5 px-md-5">
            {
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 text-center">
                <img
                  src={
                    details?.image?.includes("http")
                      ? details?.image
                      : "assets/images/headerlogo.png"
                  }
                  alt=""
                  className="detailImg"
                />
              </div>
            }
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">name</p>
                <h6 className="">{details?.name || "NA"}</h6>
              </div>
            </div>
            {(details?.class_type === "adult" ||
              details?.class_type === "both") && (
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">adult price</p>
                  <h6 className="text-break">
                    <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                    {details?.adult_price || "NA"}
                  </h6>
                </div>
              </div>
            )}
            {(details?.class_type === "child" ||
              details?.class_type === "both") && (
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">child price</p>
                  <h6 className="text-break">
                    <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                    {details?.child_price || "NA"}
                  </h6>
                </div>
              </div>
            )}
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Discipline</p>
                <h6 className="text-break">
                  {details?.discipline_details?.name || "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Booking Type</p>
                <h6 className="text-break">
                  {details?.booking_type ? "Block Booking" : "Single Lesson Booking"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Area</p>
                <h6 className="text-break">
                  {details?.areacode_details?.areacode || "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Venue</p>
                <h6 className="text-break">
                  {details?.school_details
                    ? `${details?.school_details?.name}, ${details?.school_details?.address}`
                    : "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">total seats</p>
                <h6 className="text-break">
                  {details?.total_seats === 0
                    ? "Unlimited"
                    : details?.total_seats
                    ? details?.total_seats
                    : "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">Assigned Teacher</p>
                <h6 className="text-break">
                  {details?.assigned_teacher?.teacher_details?.full_name ||
                    "NA"}
                </h6>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                <p className="mb-1">about</p>
                <h6 className="text-break">{details?.about || "NA"}</h6>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <div class="accordion accordion-flush" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <b>Time Slots</b>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="calendar">
                        <div className="month mb-3">
                          <ul>
                            <li
                              className="prev cursor-pointer"
                              onClick={() => {
                                if (index === 0) {
                                  setIndex(details?.time_slot_list?.length - 1);
                                } else if (
                                  index <=
                                  details?.time_slot_list?.length - 1
                                ) {
                                  setIndex(index - 1);
                                } else {
                                  setIndex(0);
                                }
                              }}
                            >
                              &#10094;
                            </li>
                            <li>
                              {details?.time_slot_list?.[index]?.month}{" "}
                              {details?.time_slot_list?.[index]?.year}
                            </li>
                            <li
                              className="next cursor-pointer"
                              onClick={() => {
                                if (
                                  index <
                                  details?.time_slot_list?.length - 1
                                ) {
                                  setIndex(index + 1);
                                } else {
                                  setIndex(0);
                                }
                              }}
                            >
                              &#10095;
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="bg-white px-3 py-3 text-capitalize position-relative h-100">
                        <h6 className="text-break">
                          <div className="row">
                            {details?.time_slot_list?.[index]?.time_slot?.map(
                              (itemm, i) => (
                                <div
                                  className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3"
                                  key={i}
                                >
                                  <label
                                    htmlFor={`classes1${i}`}
                                    className="student__details classDetails"
                                  >
                                    <h5>
                                      {moment(itemm?.date).format("dddd")}{" "}
                                      {moment(itemm?.date).format("DD MMM")}
                                    </h5>
                                    <p className="mb-0">
                                      <label className="theme-color">
                                        {itemm?.time_slot_for === "both"
                                          ? "Age Groups: "
                                          : "Age Group: "}
                                      </label>{" "}
                                      {itemm?.time_slot_for === "both"
                                        ? `Adult | Child`
                                        : itemm?.time_slot_for}
                                    </p>
                                    <p className="mb-0">
                                      <label className="theme-color">
                                        Start Time:
                                      </label>{" "}
                                      {moment(
                                        itemm?.start_time,
                                        "hh:mm:ss"
                                      ).format("hh:mm a")}
                                    </p>
                                    <p className="mb-0">
                                      <label className="theme-color">
                                        End Time:
                                      </label>{" "}
                                      {moment(
                                        itemm?.end_time,
                                        "hh:mm:ss"
                                      ).format("hh:mm a")}
                                    </p>
                                    {itemm?.isBooked && (
                                      <p className="text-danger">
                                        Already Booked
                                      </p>
                                    )}
                                    <p
                                      className={
                                        itemm?.isCancelled
                                          ? "text-danger"
                                          : "text-success"
                                      }
                                    >
                                      Status:{" "}
                                      {itemm?.isCancelled
                                        ? "Cancelled"
                                        : "Active"}
                                    </p>
                                    {itemm?.isCancelled && (
                                      <p className={"text-danger"}>
                                        Reason:{" "}
                                        {itemm?.cancel_reason ||
                                          "Not Specified"}
                                      </p>
                                    )}
                                    <button
                                      className="btn btn-cancel p-0 float-end w-50 mt-2 fw-normal mb-0 fs-5"
                                      onClick={() => {
                                        if (!itemm?.isCancelled) {
                                          setCancelClassDetail({
                                            time_slot_id: itemm?.time_slot_id,
                                            class_id: id,
                                            date: itemm?.date,
                                          });
                                          handleShow();
                                          // handleCancel(
                                          //   itemm?.time_slot_id,
                                          //   data?.class_details?.id
                                          // );
                                        }
                                      }}
                                      disabled={submitting}
                                    >
                                      {itemm?.isCancelled
                                        ? "Cancelled"
                                        : "Cancel Class"}
                                    </button>
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {details?.holiday_list?.length > 0 && (
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                <div class="accordion accordion-flush" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        <b>Holidays</b>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="bg-white px-3 py-3 text-capitalize position-relative h-100">
                          <h6 className="text-break">
                            <div className="row">
                              {details?.holiday_list?.map((timeSlot, i) => (
                                <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                                  <p className="">
                                    <li>
                                      {moment(timeSlot?.date).format(
                                        "dddd, DD MMM YYYY"
                                      )}
                                    </li>
                                  </p>
                                </div>
                              ))}
                            </div>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="col-sm-12 text-center"
              onClick={() => {
                (async () => {
                  await localStorage.setItem("register_for", "event");
                })();
                dispatch(changeRegisterFor("class"));
                navigate(`/register/${id}`);
              }}
            >
              {" "}
              <button className="btn btn-save ">View Register</button>
            </div>
          </div>
        </div>
        <Cancel
          page="Class"
          show={show}
          submitting={submitting}
          setSubmitting={setSubmitting}
          handleShow={handleShow}
          handleCancel={(val) => {
            handleCancel(val);
          }}
        />
      </article>
    </>
  );
}

export default ClassDetail;
