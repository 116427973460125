import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { FetchStudentList, studentListSelector } from "../student/studentslice";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../component/Loader";
import {
  FetchAllOtherStudents,
  FetchAllOtherStudentsForEvent,
} from "./RegisterSlice";
import { useCallback } from "react";

const style = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    borderWidth: 0,

    "&:hover": {
      borderColor: "transparent",
      outline: "0",
      boxShadow: "none",
    },
  }),
  container: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    backgroundImage: "none",
    paddingRight: "2px",
    borderRadius: "10px",
  }),
};

function AddNewStudent({
  id,
  show,
  handleShow,
  handleAddStudentToRegister,
  // students,
  availableTimeSlots,
  availableDates,
  register_for,
  class_type,
  submitting,
  setSubmitting,
}) {
  console.log(register_for, "Class type");
  const dispatch = useDispatch();
  const [students, setStudents] = useState([]);

  // const getStudentListing = useCallback(() => {
  //   if (register_for === "class") {
  //     console.log("Class student running");
  //     dispatch(FetchAllOtherStudents(id)).then((res) => {
  //       console.log(res, "Student for class");
  //       setStudents(res?.payload?.data?.student_list);
  //     });
  //   } else if (register_for === "event") {
  //     console.log("Event student running");
  //     dispatch(FetchAllOtherStudentsForEvent(id)).then((res) => {
  //       console.log(res, "Student for class");
  //       setStudents(res?.payload?.data?.student_list);
  //     });
  //   }
  // }, []);

  useEffect(() => {
    dispatch(FetchStudentList({ page: 1, limit: 1000000000, search: "" })).then(
      (res) => {
        setStudents(res?.payload?.data?.student_list);
      }
    );

    // getStudentListing();
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      register_for,
      iTime: "",
      iDate: "",
      selectedStudent: null,
    },
    validationSchema: Yup.object({
      register_for: Yup.string(),
      iTime: Yup.object({
        start_time: Yup.string(),
        end_time: Yup.string(),
        time_slot_id: Yup.string(),
      }).when("register_for", {
        is: "class",
        then: Yup.object({
          start_time: Yup.string().required("Required"),
          end_time: Yup.string().required("Required"),
          time_slot_id: Yup.string().required("Required"),
        }),
      }),
      iDate: Yup.object({ date: Yup.string() }).when("register_for", {
        is: "class",
        then: Yup.object({ date: Yup.string().required("Required") }),
      }),
      selectedStudent: Yup.object().typeError("Required").required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      if (values.selectedStudent) {
        handleAddStudentToRegister(
          values.selectedStudent,
          values.iTime,
          values.iDate,
          class_type,
          values.addedBy = "ADMIN"
        );
      } else {
        toast.error("Kindly select a student");
      }
    },
  });
  const errorMessageHandler = (students,selectedStudent) => {
    const wallet_student = students.filter(student => student.id == selectedStudent.id)
    if(Number(wallet_student[0]['parent_details']['wallet']) < 0){
      toast.error("This student has debt!");
    }
  }

  return (
    <Modal show={show} onHide={handleShow} size="lg" centered>
      <div className=" modal-dialog-centered">
        <div className="modal-content body-bg">
          <div className="modal-header border-0 justify-content-center">
            <h5>Add Student</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleShow}
            ></button>
          </div>
          <div className="modal-body mb-4 p-4">
            <form className="form-style" onSubmit={formik.handleSubmit}>
              {register_for === "class" && (
                <div className="d-flex gap-3">
                  <div className="form-group selectAreaCode">
                    <label className="fw-bold">Select Time</label>
                    <div className="input-container">
                      {/* <select
                        className="form-control pl-5 form-select timeSelectWidth"
                        name="iTime"
                        onChange={(e) => {
                          // setTime(e.target.value);
                          formik.setFieldValue("iTime", e.target.value);
                        }}
                        value={formik.values.iTime}
                      >
                        <option value="">Select Time</option>

                        {availableTimeSlots?.map((item, i) => (
                          <option value={item} key={i}>
                            {moment(item, "hh:mm:ss").format("hh:mm a")}
                          </option>
                        ))}
                      </select> */}

                      <Select
                        // isMulti
                        options={availableTimeSlots || undefined}
                        getOptionLabel={(option) =>
                          `${moment(option?.start_time, "hh:mm:ss").format(
                            "hh:mm a"
                          )}-${moment(option?.end_time, "hh:mm:ss").format(
                            "hh:mm a"
                          )}`
                        }
                        getOptionValue={(option) => {
                          return {
                            available_seats: option?.available_seats,

                            start_time: option?.start_time,
                            end_time: option?.end_time,
                            time_slot_id: option?.time_slot_id,
                          };
                        }}
                        styles={style}
                        className="pl-5 form-select timeSelectWidth"
                        value={formik.values.iTime || undefined}
                        onChange={(val) => {
                          formik.setFieldValue("iTime", val);
                        }}
                        name="iTime"
                      />
                      <img
                        src="assets/images/event-name.png"
                        className="input-img inputImage"
                        alt=""
                      />
                    </div>
                    {formik.errors.iTime?.start_time && (
                      <span className="text-danger">
                        {formik.errors.iTime?.start_time}
                      </span>
                    )}
                  </div>
                  <div className="form-group selectAreaCode">
                    <label className="fw-bold">Select Date</label>

                    <div className="input-container">
                      {/* <select
                        className="form-control pl-5 form-select timeSelectWidth"
                        name="iDate"
                        onChange={(e) => {
                          formik.setFieldValue("iDate", e.target.value);
                        }}
                        value={formik.values.iDate}
                      >
                        <option value="">Select Date</option>

                        {availableDates?.map((item, i) => (
                          <option value={item} key={i}>
                            {moment(item).format("DD MMM YYYY")}
                          </option>
                        ))}
                      </select> */}
                      <Select
                        // isMulti
                        options={availableDates || undefined}
                        getOptionLabel={(option) =>
                          `${moment(option.date).format("DD MMM YYYY")}`
                        }
                        styles={style}
                        getOptionValue={(option) => option}
                        className="pl-5 form-select timeSelectWidth"
                        value={formik.values.iDate}
                        onChange={(val) => {
                          formik.setFieldValue("iDate", val);
                        }}
                        name="iDate.date"
                      />
                      <img
                        src="assets/images/event-name.png"
                        className="input-img inputImage"
                        alt=""
                      />
                    </div>
                    {formik.errors.iDate?.date && (
                      <span className="text-danger">
                        {formik.errors.iDate?.date}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <label className="fw-bold">Select Student</label>

              <Select
                // isMulti
                options={students || undefined}
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                getOptionValue={(option) => option?.id}
                className="mb-4"
                value={formik.values.selectedStudent}
                onChange={(val) => {
                  formik.setFieldValue("selectedStudent", val);
                  errorMessageHandler(students,val)
                }}
              />
             
              
              {formik.errors.selectedStudent &&
                formik.touched.selectedStudent && (
                  <span className="text-danger">
                    {formik.errors.selectedStudent}
                  </span>
                )}

              <div className="text-center mt-4">
                <button
                  type="submit"
                  className="btn btn-accept btn-view mt-2"
                  disabled={submitting}
                >
                  {submitting ? <Loader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddNewStudent;
