import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getWalletHistory } from "../../api/user";

const initialState = {
  list: [],
  walletList: [],
  total: 0,
};

export const WalletHistorySlice = createSlice({
  name: "wallet history",
  initialState,
  reducers: {
    lists: (state, action) => {
      state.list = action.payload;
    },
    totalCount: (state, action) => {
      state.total = action.payload;
    },
    walletLists: (state, action) => {
      state.walletList = action.payload;
    },
    walletAmount: (state, action) => {
      state.walletAmount = action.payload;
    },
  },
});

export const { lists, totalCount, details,walletLists, walletAmount } = WalletHistorySlice.actions;

export const walletListSelector = (state) => state.walletHistory.walletList;
export const totalWalletCountSelector = (state) => state.walletHistory.total;
export const walletAmountSelector = (state) => state.walletHistory.walletAmount;

export const FetchWalletHistory = createAsyncThunk(
  "FetchWalletHistory",
  async (obj, { dispatch }) => {
    try {
      const response = await getWalletHistory(obj);
      if (response?.code === 1) {
        dispatch(walletLists(response?.data?.wallet_list));
        dispatch(totalCount(response?.data?.total_wallet));
        dispatch(walletAmount(response?.data?.wallet_amount));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export default WalletHistorySlice.reducer;
