import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { variants } from "../../constant/Variants";
import { adminId, LoginReducer } from "../slice";
import { toast } from "react-toastify";
import Loader from "./../../component/Loader";
import Routers from "../../routes/Router";

const variant = {
  hidden: { opacity: 0, x: "100vw" },
  visible: {
    opacity: 1,
    x: 0,
    transtion: { duration: 1, type: "tween", ease: "easeIn" },
  },
};

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loding, setLoding] = useState(false);

  return (
    <section className="bg-image ">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-5 col-lg-6">
            <div className="mb-5">
              <img
                src="assets/images/headerlogo.png"
                className="img-fluid"
                alt="logo"
              />
            </div>

            <div className="logo-bg d-none d-md-block">
              <div className="mt-2" >
                <img
                  src="assets/images/activity.png"
                  className="img-fluid lft-img"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-7 col-lg-6 mr-0 pr-0">
            <div className="form-bg">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  toggle: false,
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Kindly enter a valid email.")
                    .required("Required"),
                  password: Yup.string().required("Required"),
                })}
                onSubmit={(values, action) => {
                  setLoding(true);
                  dispatch(
                    LoginReducer({
                      email: values.email?.trim()?.toLowerCase(),
                      password: values.password,
                    })
                  ).then((res) => {
                    setLoding(false);
                    if (res?.payload?.code === 1) {
                      (async () => {
                        await localStorage.setItem(
                          "id",
                          res?.payload?.data?.adminId
                        );
                        await localStorage.setItem(
                          "booking_fee",
                          res?.payload?.data?.booking_fee
                        );
                      })();
                      dispatch(adminId(res?.payload?.data?.adminId));
                      if (
                        res?.payload?.data?.admin_type?.toLowerCase() ===
                        "super admin"
                      ) {
                        navigate("/users", { replace: true });
                      } else {
                        let data = res?.payload?.data?.modules;
                        for (const key in data) {
                          if (Object.hasOwnProperty.call(data, key)) {
                            const slicedKey = key.replaceAll("_", "-");
                            const element = data[key];
                            if (element === true) {
                              // navigate("/users", { replace: true });
                              const firstMatchedRole = Routers.find((item) => {
                                return (
                                  item.hidden === false &&
                                  item.path
                                    .replaceAll("/", "")
                                    .replaceAll("-", " ")
                                    ?.includes(slicedKey)
                                );
                              });
                              console.log(firstMatchedRole);
                              navigate(firstMatchedRole.path, {
                                replace: true,
                              });
                              break;
                            }
                          }
                        }
                      }
                      toast.success(res?.payload?.message);
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  getFieldProps,
                  setFieldValue,
                  setFormikState,
                }) => (
                  <form className="form-style" onSubmit={handleSubmit}>
                    <div className="row">
                      <div
                        variants={variants.card}
                        className="col-sm-12 col-md-12 text-center mb-5"
                      >
                        <h2 className="mdl-ttl">Login</h2>
                      </div>
                      <div
                        variants={variants.card}
                        className="col-sm-12 col-md-12 mb-4"
                      >
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              type="email"
                              className="form-control pl-5"
                              placeholder="Email Address"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              {...getFieldProps("email")}
                            />
                            <img
                              src="assets/images/Email-Address.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          {errors.email && touched.email && (
                            <div className="text-red">{errors.email}</div>
                          )}
                        </div>
                      </div>
                      <div
                        variants={variants.card}
                        className="col-sm-12 col-md-12 mb-4"
                      >
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              type={values.toggle ? "text" : "password"}
                              className="form-control pl-5"
                              placeholder="Password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              {...getFieldProps("password")}
                            />
                            <img
                              src="assets/images/password.png"
                              className="input-img"
                              alt=""
                            />
                            <span
                              className={
                                values.toggle
                                  ? "pass-hide field-icon "
                                  : "pass-view field-icon "
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      toggle: !prevState.values.toggle,
                                    },
                                  };
                                });
                              }}
                            ></span>
                          </div>
                          {errors.password && touched.password && (
                            <div className="text-red">{errors.password}</div>
                          )}
                        </div>
                      </div>
                      <div
                        variants={variants.card}
                        className="col-sm-12 col-md-12 text-center mb-4"
                      >
                        <Link to="/forget-password" className="forgot-pass">
                          Forgot Password?
                        </Link>
                      </div>
                      <div
                        variants={variants.card}
                        className="col-sm-12 col-md-12 text-center"
                      >
                        <button type="submit" className="btn btn-save" disabled={loding}>
                          {loding ? <Loader /> : "Log In"}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
