import { configureStore } from "@reduxjs/toolkit";
import AccountDeletionRequestSlice from "../features/accountDeleteionRequest/AccountDeletionRequestSlice";
import AreaSlice from "../features/areacode/slice";
import ClassSlice from "../features/class/ClassSlice";
import ConsentSlice from "../features/consent/ConsentSlice";
import DisciplineSlice from "../features/discipline/DisciplineSlice";
import EditRequestSlice from "../features/editRequest/EditRequestSlice";
import EditUserRequestSlice from "../features/editRequest/user/EditUserRequestSlice";
import EventSlice from "../features/event/EventSlice";
import EventTypeSlice from "../features/eventType/EventTypeSlice";
import MembershipSlice from "../features/membership/MembershipSlice";
import NewsLetterSlice from "../features/newsletter/NewsLetterSlice";
import PaymentSlice from "../features/payment/PaymentSlice";
import RegisterSlice from "../features/register/RegisterSlice";
import SizeSlice from "../features/sizes/slice";
import MainSlice from "../features/slice";
import StudentSlice from "../features/student/studentslice";
import SubAdminSlice from "../features/subAdmin/SubAdminSlice";
import TeacherSlice from "../features/teacher/TeacherSlice";
import tncSlice from "../features/tnc/tncSlice";
import UserSlice from "../features/user/UserSlice";
import BookingSlice from "./../features/booking/BookingSlice";
import SmsSlice from "./../features/manageSMS/SmsSlice";
import WalletHistorySlice from "../features/user/WalletHistorySlice";

export const store = configureStore({
  reducer: {
    user: UserSlice,
    teacher: TeacherSlice,
    event: EventSlice,
    membership: MembershipSlice,
    class: ClassSlice,
    register: RegisterSlice,
    booking: BookingSlice,
    accountDeletionRequest: AccountDeletionRequestSlice,
    main: MainSlice,
    area: AreaSlice,
    size: SizeSlice,
    student: StudentSlice,
    eventType: EventTypeSlice,
    payment: PaymentSlice,
    discipline: DisciplineSlice,
    subAdmin: SubAdminSlice,
    consent: ConsentSlice,
    tnc: tncSlice,
    edit: EditRequestSlice,
    editUser: EditUserRequestSlice,
    newsletter: NewsLetterSlice,
    sms: SmsSlice,
    walletHistory: WalletHistorySlice,
  },
});
